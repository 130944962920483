.league-leaders-field{
	.css-117axj9-control{
		min-width: 0;
	}

	.css-1mbsuax-menu{
		div{
			max-height: 15.875em;
		}
	}
}