.page-container {
    display: flex;
    width: 100%;
    min-height: 100%;
    overflow-x: hidden;
    background-color: $color-fog;

    .nav-container {
        position: fixed;
        width: 240px;
        height: 100%;
        background-color: $color-mlb-navy;
        border-bottom: solid 10px $color-mlb-red;
        z-index: 700;

        .logo-container {
            background-color: $color-dark-blue;
            padding: 8px 0 8px 20px;
            border-bottom: solid 1px #404255;
        }

        .nav-bar {
            margin-top: 36px;

            .nav-link-container {
                padding: 5px 0 5px 0;
                font-size: 1.2em;
                font-weight: bold;
              
                div {
                  min-width: 4.5rem;
                }
              
                &:hover {
                  cursor: pointer;
                }
              
                i {
                  margin-left: 18px;
                  margin-right: 16px;
                  text-align: center;
                  color: $color-blue-grey;
                }
              
                i:hover,
                span {
                  color: $color-blue-grey;
                }

                &.open {
                    background-color: $color-dark-blue;
                    border-left: solid 4px $color-mlb-red;

                    i {
                        margin-left: 14px;
                        color: #fff;
                    }

                    i:hover, span {
                        color: #fff;
                    }
                }

                .nav-icon {
                    width: 20px;
                }
            }
        }

        .comp-info-container {
            padding: 1em;
            color: white !important;

            h6, .info-section {
              padding-bottom: 0.25em;
            }

            a {
              color: #007bff;
            }

            .row {
              font-size: 1em;
            }

            label {
              color: white !important;
            }

            .comparison-label label {
              font-size: 1.2em !important;
            }

            .comparison-text a {
              color: #00dcff !important;
            }
        }
    }

    .content-container {
        position: relative;
        left: 240px;
        width: calc(100% - 240px);
        overflow-x: hidden;
        z-index: 699;

        .child-container {
            position: absolute;
            top: 96px;
            width: 100%;
        }
    }
}