.header-btn {
	@media(min-width: 992px){
		flex: 0 0 10em;
	}

	@media(min-width: 1200px){
		flex: 0 0 10em;
	}
}


.user-header{
	.feedback{
    	font-weight: normal;
	}

	.logout{
		cursor: pointer;
	}

	.caret{
		font-size: 1.5rem;
	}

	.link{
		color: $color-link-primary;

		&.top-level{
			font-weight: 600;
		}

		i{
			color: $color-link-primary !important;
			font-weight: 600;
		}
	}
}