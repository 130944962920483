@import "../../variables.scss";

.alert-danger_persist {
    background-color: $color-red-danger !important;
    color: $color-red-danger-two !important;
    border: 1px solid $color-red-danger-two !important;
}

.alert-warning_persist {
    background-color: $color-yellow-warning !important;
    color: $color-yellow-warning-two !important;
    border: 1px solid $color-yellow-warning-two !important;
}