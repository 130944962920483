@import "../../variables.scss";

@mixin pia-mat-table($min-table-height: $size-row-height, $max-table-height: 100%, $header-row-height: $size-row-height, $row-height: $size-row-height) {
    .pia-mat-table-container {
        min-height: $min-table-height;
        max-height: $max-table-height;
        table {
            thead {
                tr {
                    th {
                        font-size: 1rem;
                        font-weight: bold;
                        height: $header-row-height;
                        padding: .25rem .75rem;
                    }
                }
            }
            tbody {
                tr {
                    td {
                        font-size: 1rem;
                        font-weight: 300;
                        height: $row-height;
                        padding: .25rem .75rem;

                        .pia-mat-table-toggle-expand {
                            float: right;
                        }
                    }
                }
                tr.pia-mat-table-row-highlighted {
                    background-color: #fbf8ca;
                }
                tr.pia-mat-table-row-expanded {
                    background-color: $color-light-blue-two;
                }
            }
        }
    }
}

@mixin pia-responsive-grid($repeat-length, $min-col-width: 15rem, $column-gap: $size-gutter, $row-gap: $size-gutter) {
    display: grid;
    // Needs to be "Min" instead of "min" to avoid interfering with Sass min function
    grid-template-columns: repeat($repeat-length, minmax(Min($min-col-width, 100%), 1fr));
    column-gap: $column-gap;
    row-gap: $row-gap;
}

.comp-container{
    [class*='BestSelect']{
        input {
            color: white !important;
        }
    }
}