.player-list-positions{
	.css-117axj9-control{
		height: auto !important;

		div {
			height: auto !important;
		}
	}
}

.criterion-wrapper{
	&.group{
		background-color: $color-light-blue-two !important;
	}

	background-color: $color-light-blue;
	border: .1em solid $color-fog;
	border-radius: .5em;

	.group-button{
		align-self: flex-end;

	}

	.group-header{
		width: 9rem;
	}

	.control-buttons{
		margin-left: auto;
	}

}

.player-list-criteria-table{
	.react-grid-HeaderCell-sortable{
		.pull-right{
			position: relative;
   			bottom: 3px;
		}
	}

	.react-grid-HeaderRow{
		overflow-y: hidden;
	}
}
	
