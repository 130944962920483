.header-input{
	&.error{
		input {
			border: 1px solid #F00;
		}
	}
}

input {
	&.error {
		border: 1px solid #F00 !important;
	}
}