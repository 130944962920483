//import "../node_modules/bootstrap/scss/_functions";
//import "../node_modules/bootstrap/scss/_variables";

// Variables to export
$color-blue-grey: #92a5ba;
$color-blue-grey-two: #77879a;
$color-blue-highlight: #a8def0;
$color-dark-blue: #03142b;
$color-dark-blue-two: #00549D;
//$color-dark-grey: #666666;
$color-fog: #dee5ed;
$color-grey: #d2d2d2;
//$color-grey-two: #c2c2c2;
$color-grey-three: #b8c5d3;
$color-light-blue: #f5f7fa;
$color-light-blue-two: #ebf1f8;
$color-light-grey: #f2f3f4;
$color-light-grey-two: #e5e6e8;
$color-link-primary: $color-dark-blue-two;
$color-mlb-navy: #041e42;
$color-mlb-red: #bf0d3e;
$color-primary: #1f296a;
$color-red-danger: #f4dedc;
$color-red-danger-two: #9f3b32;
$color-white: #ffffff;
$color-white-two: #ececec;
$color-yellow-highlight: #fed54f;
$color-yellow-warning: #fff3cd;
$color-yellow-warning-two: #856404;
$size-gutter: 12px;
$size-gutter-num: 12;
$size-icon: 1.1rem;
$size-row-height: 28px;
$size-row-height-num: 38;
$size-header-height-num: 28;

// Variables to override
//$headings-margin-bottom: 0 !important;
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    5: 5%,
    10: 10%,
    15: 15%,
    20: 20%,
    25: 25%,
    30: 30%,
    35: 35%,
    40: 40%,
    45: 45%,
    50: 50%,
    75: 75%,
    80: 80%,
    85: 85%,
    90: 90%,
    95: 95%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

:export {
  blue-grey: $color-blue-grey;
  blue-highlight: $color-blue-highlight;
  dark-blue: $color-dark-blue;
  //dark-grey: $color-dark-grey;
  fog: $color-fog;
  grey: $color-grey;
  //grey-two: $color-grey-two;
  grey-three: $color-grey-three;
  light-blue: $color-light-blue;
  light-blue-two: $color-light-blue-two;
  light-grey: $color-light-grey;
  link-primary: $color-link-primary;
  mlb-navy: $color-mlb-navy;
  mlb-red: $color-mlb-red;
  primary: $color-primary;
  redDanger: $color-red-danger;
  redDangerTwo: $color-red-danger-two;
  yellow-highlight: $color-yellow-highlight;
  yellowWarning: $color-yellow-warning;
  yellowWarningTwo: $color-yellow-warning-two;
  white: $color-white;
  white-two: $color-white-two;
  size-gutter: $size-gutter;
  size-gutter-num: $size-gutter-num;
  size-icon: $size-icon;
  sizeRowHeight: $size-row-height;
  sizeRowHeightNum: $size-row-height-num;
  sizeHeaderHeightNum: $size-header-height-num;
  blue-grey-two: $color-blue-grey-two;
}