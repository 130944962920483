/* CSS variables */
:root {
    --dark-blue: #03142b;
    --mlb-red: #bf0d3e;
}

#okta-sign-in.auth-container.main-container {
    background-color: var(--dark-blue);
    border: none;
    box-shadow: none;
    width: 100%;
    margin: 0px;
}

#okta-sign-in .auth-header {
    padding-left: 42px;
}

#okta-sign-in.no-beacon .auth-header {
    padding-bottom: 0px;
}

#okta-sign-in .auth-org-logo {
    max-height: 62px;
    margin-left: 0px;
}

#okta-sign-in .infobox {
    padding-left: 0px !important;
    border: none;
    font-family: Helvetica;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    background-color: var(--dark-blue) !important;
    color: var(--mlb-red);
}

#okta-sign-in .infobox::before,
#okta-sign-in .infobox > .icon,
#okta-sign-in .o-form-input-error,
#okta-sign-in .okta-form-title,
#okta-sign-in .o-form .o-form-input .input-icon,
#okta-sign-in .o-form .o-form-input .input-tooltip,
#okta-sign-in .o-form-input-name-remember,
#okta-sign-in .auth-footer {
    display: none !important;
}

#okta-sign-in.no-beacon .auth-content {
    padding-top: 0px;
    padding-bottom: 0px;
}

.auth-content .o-form-error-container {
    height: 41px;
}

#okta-sign-in.auth-container .okta-sign-in-header {
    border: none;
}

#okta-sign-in.auth-container .okta-form-input-field {
    border: none;
    background-color: var(--dark-blue);
}

#okta-sign-in .o-form .o-form-input .input-icon + input {
    padding-left: 12px;
}

#okta-sign-in.auth-container .okta-form-input-field input {
    color: #ffffff;
}

#okta-sign-in.auth-container .okta-form-input-field input::placeholder {
    font-style: italic;
    color: #ffffff;
}

#okta-sign-in .o-form .input-fix input[type="text"],
#okta-sign-in .o-form .input-fix input[type="password"] {
    border: solid 1px #ffffff;
    border-radius: 4px;
    background-color: var(--dark-blue);
}

#okta-sign-in .o-form-button-bar {
    width: 104px;
    margin-top: 20px;
}

#okta-sign-in.auth-container input[type="submit"] {
    width: 104px;
    height: 24px;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    border: none;
    border-radius: 4px;
    background: var(--mlb-red);
}

#okta-sign-in.auth-container input[type="submit"]:hover {
    background: var(--mlb-red);
}

#okta-sign-in.auth-container input[type="submit"]:focus {
    background: var(--mlb-red);
}

.custom-checkbox label {
    background-image: none;
}

/*
#okta-sign-in.auth-container .link:link, #okta-sign-in.auth-container .link {
    font-family: Helvetica;
    font-size: 17px;
}

#okta-sign-in.auth-container .link:link, #okta-sign-in.auth-container .link:visited {
    color: #ffffff;
}

#okta-sign-in.auth-container .button-primary {
    font-family: Roboto;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    border: none;
    border-radius: 4px;
    background: var(--mlb-red);
}
*/