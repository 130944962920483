@import "variables";

.react-grid-Main {
    outline: none !important;
    line-height: normal;

    .react-grid-Grid {
        border: none;
        font-size: 12px;

        .react-grid-Header {
            .react-grid-HeaderCell {
                background-color: $color-fog !important;
                border-bottom: solid 1px $color-blue-grey !important;

                &[class^="icon-"], &[class*=" icon-"] {
                    display: flex !important;
                    justify-content: center;
                    align-items: center;
                    padding-top: 0;
                    padding-bottom: 0;
                    overflow: hidden;
                }

                &:not(.react-grid-HeaderCell--frozen) {
                    border-left: solid 1px $color-blue-grey !important;
                } 
            }
        }

        .react-grid-Viewport {
            .react-grid-Row {
                &.row-highlighted .react-grid-Cell {
                    background-color: #fbf8ca !important;
                }
    
                &:first-child {
                    border-top: none !important;
                }
            }
            
            .react-grid-Cell {
                background-color: $color-light-blue !important;
                border-top: solid 1px $color-fog !important;
    
                &:not(.react-grid-Cell--frozen) {
                    border-left: solid 1px $color-fog !important;
                }

                border-bottom: 0 !important;
            }
        }
    
        .removeCheckBox .react-grid-checkbox-container  {
            display: none;
        }

        .react-grid-checkbox-container{
                .react-grid-checkbox-label {
                margin-bottom: 0 !important;

                &:before {
                    width: 1.5em !important;
                    height: 1.5em !important;

                }
            }
        }
    }
}

.no-grid-header {
    .react-grid-Header {
        display: none;
    }

    .react-grid-Viewport {
        top: 0px !important;
    }
}

.total-row {
    font-weight: bold;
    
    .react-grid-Canvas {
        overflow-y: hidden !important;
    }
}

.news-header-tool-tip{
    &:after{
        left: 24.5% !important;
    }
}